<template>
  <div class="notice-detail wrapper" v-if="notice">
    <a-spin :spinning="loading">
      <div class="detail-title">
        <div class="h1">{{ notice.notice_title }}</div>
        <div class="flex flex-center">
          <div class="park-name" v-if="notice.park_name">
            {{ notice.park_name }}
          </div>
          <div class="notice-time">{{ notice.notice_time }}</div>
        </div>
      </div>
      <div class="notice-content" v-html="notice.notice_content"></div>
      <div
        v-if="notice.notice_attachment?.length > 0"
        style="padding-bottom: 50px"
      >
        <div class="line"></div>
        <attachment :data="notice_attachment"></attachment>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getNoticeData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import Attachment from "@/components/attachment.vue";
import { fromType } from "vue-types";
export default defineComponent({
  name: "NoticeDetail",
  components: {
    Attachment
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageData = reactive({
      notice: null,
      noticeId: null,
      loading: true,
      notice_attachment:[]
    });
    const getNoticeDataFun = _noticeId => {
      getNoticeData({ notice_id: _noticeId })
        .then(res => {
          if (res.code == 200) {
            pageData.notice = res.data.notice;
            pageData.notice_attachment=res.data.notice.notice_attachment
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };

    watch(
      () => route.query,
      val => {
        if (route.path == "/notice/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.noticeId = query[0];
            getNoticeDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.notice = null;
            message.error("暂无公告信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      
    };
  }
});
</script>
<style lang="less" scoped>
.notice-detail {
  .detail-title {
    padding: 40px;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    .h1 {
      font-size: 28px;
      color: #212531;
      margin-bottom: 20px;
    }
    .flex-center {
      justify-content: center;
    }
    .park-name {
      min-width: 80px;
      height: 22px;
      line-height: 22px;
      border-radius: 11px;
      border: solid 1px #1276cb;
      color: #1276cb;
      font-size: 14px;
      text-align: center;
      margin-right: 20px;
    }
    .notice-time {
      color: #212531;
      opacity: 0.7;
      font-size: 16px;
    }
  }
  .notice-content {
    padding: 40px 0;
  }
  .line {
    height: 20px;
    background-color: #f3f5f8;
  }
}
</style>
